import React from "react";

import styled from "styled-components";

interface Props {
  logo: React.ReactNode;
}
export default function LogoItem(props: Props) {
  return <Container>{props.logo}</Container>;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 80px;
  width: fit-content;

  scale: 1.3;
`;
